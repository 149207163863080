import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const HcvRollIsolationTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "機械名", value: "machineCode" },
  { text: "作業者1", value: "userId1" },
  { text: "作業者2", value: "userId2" },
  { text: "昼勤/夜勤", value: "shift" },
  { text: "受注番号", value: "acceptNo" },
  { text: "受注番号品 完了", value: "successFalg" },
  { text: "品名", value: "itemName" },
  { text: "素線構成", value: "bareWireStructure" },
  { text: "サイズ", value: "size" },
  { text: "色", value: "color" },
  { text: "数量(m)", value: "quantity" },
  { text: "押出外径(mm)", value: "pushOuterDiameter" },
  { text: "絶縁体厚さ(mm)規格", value: "isolationThicknessStandard" },
  { text: "絶縁体厚さ(mm)最小", value: "isolationThicknessMin" },
  { text: "耐圧(KV)", value: "withstandPressure" },
  { text: "被覆材料", value: "coatingMaterial" },
  { text: "ロットNo.1", value: "lotNo1" },
  { text: "ロットNo.2", value: "lotNo2" },
  { text: "ロットNo.3", value: "lotNo3" },
  { text: "偏肉", value: "thicknessDeviation" },
  { text: "製品異常処置報告(修理・廃棄・解体)", value: "abnormality" },
  { text: "備考", value: "remarks" }
] as const;

export class HcvRollIsolationTable {
  workDate: string;
  updateTs: string | null;
  machineCode: string | null;
  userId1: string | null;
  userId2: string | null;
  shift: string | null;
  acceptNo: string | null;
  successFalg: string | null;
  itemName: string | null;
  size: string | null;
  bareWireStructure: string | null;
  color: string | null;
  quantity: number | null;
  pushOuterDiameter: number | null;
  isolationThicknessStandard: number | null;
  isolationThicknessMin: number | null;
  withstandPressure: number | null;
  coatingMaterial: string | null;
  lotNo1: string | null;
  lotNo2: string | null;
  lotNo3: string | null;
  thicknessDeviation: string | null;
  abnormality: string | null;
  remarks: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    machineCode: string | null;
    userId1: string | null;
    userId2: string | null;
    shift: string | null;
    acceptNo: string | null;
    successFalg: string | null;
    itemName: string | null;
    size: string | null;
    bareWireStructure: string | null;
    color: string | null;
    quantity: number | null;
    pushOuterDiameter: number | null;
    isolationThicknessStandard: number | null;
    isolationThicknessMin: number | null;
    withstandPressure: number | null;
    coatingMaterial: string | null;
    lotNo1: string | null;
    lotNo2: string | null;
    lotNo3: string | null;
    thicknessDeviation: string | null;
    abnormality: string | null;
    remarks: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.machineCode = props.machineCode;
    this.userId1 = props.userId1;
    this.userId2 = props.userId2;
    this.shift = props.shift;
    this.acceptNo = props.acceptNo;
    this.successFalg = props.successFalg;
    this.itemName = props.itemName;
    this.size = props.size;
    this.bareWireStructure = props.bareWireStructure;
    this.color = props.color;
    this.quantity = props.quantity;
    this.pushOuterDiameter = props.pushOuterDiameter;
    this.isolationThicknessStandard = props.isolationThicknessStandard;
    this.isolationThicknessMin = props.isolationThicknessMin;
    this.withstandPressure = props.withstandPressure;
    this.coatingMaterial = props.coatingMaterial;
    this.lotNo1 = props.lotNo1;
    this.lotNo2 = props.lotNo2;
    this.lotNo3 = props.lotNo3;
    this.thicknessDeviation = props.thicknessDeviation;
    this.abnormality = props.abnormality;
    this.remarks = props.remarks;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const HcvRollIsolationText = {
  workDate: { text: "作業年月日", value: "workDate" },
  machineCode: { text: "機械名", value: "machineCode" },
  userId1: { text: "作業者1", value: "userId1" },
  userId2: { text: "作業者2", value: "userId2" },
  shift: { text: "昼勤/夜勤", value: "shift" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFalg: { text: "受注番号品 完了", value: "successFalg" },
  itemName: { text: "品名", value: "itemName" },
  bareWireStructure: { text: "素線構成", value: "bareWireStructure" },
  size: { text: "サイズ", value: "size" },
  color: { text: "色", value: "color" },
  quantity: { text: "数量(m)", value: "quantity" },
  pushOuterDiameter: { text: "押出外径(mm)", value: "pushOuterDiameter" },
  isolationThicknessStandard: {
    text: "絶縁体厚さ(mm)規格",
    value: "isolationThicknessStandard"
  },
  isolationThicknessMin: {
    text: "絶縁体厚さ(mm)最小",
    value: "isolationThicknessMin"
  },
  withstandPressure: { text: "耐圧(KV)", value: "withstandPressure" },
  coatingMaterial: { text: "被覆材料", value: "coatingMaterial" },
  lotNo1: { text: "ロットNo.1", value: "lotNo1" },
  lotNo2: { text: "ロットNo.2", value: "lotNo2" },
  lotNo3: { text: "ロットNo.3", value: "lotNo3" },
  thicknessDeviation: { text: "偏肉", value: "thicknessDeviation" },
  abnormality: {
    text: "製品異常処置報告(修理・廃棄・解体)",
    value: "abnormality"
  },
  remarks: { text: "備考", value: "remarks" }
} as const;

export class HcvRollIsolation extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  machineCode: string | null;
  userId1: string | null;
  userId2: string | null;
  shift: string | null;
  acceptNo: string | null;
  successFalg: string | null;
  itemName: string | null;
  size: string | null;
  bareWireStructure: string | null;
  color: string | null;
  quantity: number | null;
  pushOuterDiameter: number | null;
  isolationThicknessStandard: number | null;
  isolationThicknessMin: number | null;
  withstandPressure: number | null;
  coatingMaterial: string | null;
  lotNo1: string | null;
  lotNo2: string | null;
  lotNo3: string | null;
  thicknessDeviation: string | null;
  abnormality: string | null;
  remarks: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: HcvRollIsolation);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: HcvRollIsolation | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof HcvRollIsolation) {
      this.postCode = _props.postCode;
      this.machineCode = _props.machineCode;
      this.userId1 = _props.userId1;
      this.userId2 = _props.userId2;
      this.shift = _props.shift;
      this.acceptNo = _props.acceptNo;
      this.successFalg = _props.successFalg;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.bareWireStructure = _props.bareWireStructure;
      this.color = _props.color;
      this.quantity = _props.quantity;
      this.pushOuterDiameter = _props.pushOuterDiameter;
      this.isolationThicknessStandard = _props.isolationThicknessStandard;
      this.isolationThicknessMin = _props.isolationThicknessMin;
      this.withstandPressure = _props.withstandPressure;
      this.coatingMaterial = _props.coatingMaterial;
      this.lotNo1 = _props.lotNo1;
      this.lotNo2 = _props.lotNo2;
      this.lotNo3 = _props.lotNo3;
      this.thicknessDeviation = _props.thicknessDeviation;
      this.abnormality = _props.abnormality;
      this.remarks = _props.remarks;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "HRISOL";
    this.machineCode = null;
    this.userId1 = null;
    this.userId2 = null;
    this.shift = "0";
    this.acceptNo = null;
    this.successFalg = "0";
    this.itemName = null;
    this.size = null;
    this.bareWireStructure = null;
    this.color = null;
    this.quantity = null;
    this.pushOuterDiameter = null;
    this.isolationThicknessStandard = null;
    this.isolationThicknessMin = null;
    this.withstandPressure = null;
    this.coatingMaterial = null;
    this.lotNo1 = null;
    this.lotNo2 = null;
    this.lotNo3 = null;
    this.thicknessDeviation = "0";
    this.abnormality = null;
    this.remarks = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
